import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { PlatformResponsiveModal } from '@/components/ui/PlatformResponsiveModal/PlatformResponsiveModal';
import { PlatformSidebarHeight } from '@/components/ui/PlatformSidebar';
import { PopUpTypeMode } from '@/components/ui/Modal';
import { IconRoundCountry } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconRoundCountry';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { DomainSelectorModal } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/DomainSelectorModal';
import styles from './HeaderLanguageSelector.module.scss';

interface Props {
  isOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
}

export const MobileLanguageSelector: FC<Props> = ({
  isOpen,
  toggleModal,
}) => (
  <>
    <Button
      onClick={() => toggleModal(true)}
      LeftIcon={IconRoundCountry}
      RightIcon={IconChevronDown}
      size={Button.size.Large}
      mode={Button.mode.TransparentLight}
      className={styles.languageSelector}
      data-qa="header-change-domain-button"
    />
    <PlatformResponsiveModal
      isOpen={isOpen}
      modalType={PopUpTypeMode.Warning}
      shouldCloseOnEsc
      sidebarVhHeight={PlatformSidebarHeight.Auto}
      shouldCloseOnOverlayClick
      closeModal={() => toggleModal(false)}
    >
      <DomainSelectorModal />
    </PlatformResponsiveModal>
  </>
);
