import getConfig from 'next/config';
import { AppEnvironments } from '@/lib/constants/general';

const { publicRuntimeConfig = {} } = getConfig() || {};
const ENV: AppEnvironments = publicRuntimeConfig.APP_ENV;

const ENV_TO_URL_PREFIX = {
  local: 'local.',
  development: 'dev.',
  staging: 'stage.',
  production: '',
  test: '',
};

export const KNOWELY_HOME = `https://${ENV_TO_URL_PREFIX[ENV]}knowely.com`;
